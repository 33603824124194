import React from "react";
import "../styles/Projects.css";
import BuzzImage from "../assets/PortfolioSnap1Buzz.PNG";
import DndImage from "../assets/PortfolioSnap2Dnd.PNG";

function Projects() {
  return (
    <div className="projects">
      <h1>My Personal Projects</h1>
      <div className="projectList">
        {/* Project 1 */}
        <div className="projectItem">
          <a 
            href="https://roymaegus.github.io/buzzfeed-clone/" 
            target="_blank" 
            rel="noopener noreferrer"
            title="BuzzFeed Style Quiz App"
          >
            <img src={BuzzImage} alt="BuzzFeed Clone Project" className="projectImage" />
          </a>
          <p className="projectDescription">
          BuzzFeed Style Quiz App: Discover Your Inner Cheese

This interactive quiz app is built using HTML, CSS, and JavaScript, and features a dynamic,
 user-friendly interface that mimics the popular BuzzFeed quiz format. The app leverages DOM
  manipulation and event handling to provide an engaging experience, guiding users through a
   series of questions to determine their unique cheese personality.
<br/>   
The app uses JavaScript to dynamically update the quiz flow based on user selections,
 providing instant feedback and results. CSS is employed to create a visually appealing
  layout with responsive design elements, ensuring compatibility across different devices
   and screen sizes. With its lightweight codebase and intuitive navigation, this app demonstrates
    a fun application of front-end web development principles.
          </p>
        </div>

        {/* Project 2 */}
        <div className="projectItem">
          <a 
            href="https://roymaegus.github.io/dnd-lang-1/" 
            target="_blank" 
            rel="noopener noreferrer"
            title="DnD Language Learning App"
          >
            <img src={DndImage} alt="DnD Language Learning App" className="projectImage" />
          </a>
          <p className="projectDescription">
          The Spanish Sentence Builder app is an interactive learning tool designed to help users practice and master Spanish
           sentence construction through a series of engaging challenges. The app offers 10 unique challenges,
            each focusing on different aspects of the Spanish language, such as basic sentence formation, questions,
             reflexive verbs, object pronouns, imperative commands, conditional sentences, and future tense usage.

<br />Users drag and drop words into the correct order to form grammatically accurate sentences. Each challenge provides
 hints and immediate feedback on the correctness of the sentence, allowing users to learn and improve their
  language skills progressively. Whether you are a beginner or an intermediate Spanish learner, this app offers
   a fun and effective way to enhance your vocabulary and grasp of Spanish grammar.

Start building your confidence in Spanish today with the Spanish Sentence Builder app!
          </p>
        </div>
      </div>
    </div>
  );
}

export default Projects;


// import React from "react";
// import "../styles/Projects.css";
// import BuzzImage from "../assets/PortfolioSnap1Buzz.PNG";
// import DndImage from "../assets/PortfolioSnap2Dnd.PNG";

// function Projects() {
//   return (
//     <div className="projects">
//       <h1>My Personal Projects</h1>
//       <div className="projectList">
//         {/* Project 1 */}
//         <div className="projectItem">
//           <a 
//             href="https://roymaegus.github.io/buzzfeed-clone/" 
//             target="_blank" 
//             rel="noopener noreferrer"
//             title="BuzzFeed Style Quiz App"
//           >
//             <img src={BuzzImage} alt="BuzzFeed Clone Project" className="projectImage" />
//           </a>
//           <p className="projectDescription">
//             BuzzFeed Style Quiz App: Discover what kind of cheese you are with this fun, interactive quiz app.
//           </p>
//         </div>

//         {/* Project 2 */}
//         <div className="projectItem">
//           <a 
//             href="https://roymaegus.github.io/dnd-lang-1/" 
//             target="_blank" 
//             rel="noopener noreferrer"
//             title="DnD Language Learning App"
//           >
//             <img src={DndImage} alt="DnD Language Learning App" className="projectImage" />
//           </a>
//           <p className="projectDescription">
//             DnD Language Learning App: Learn a new language with a twist! This app uses Dungeons & Dragons lore to make learning fun.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Projects;


// import React from "react";
// import "../styles/Projects.css";
// import BuzzImage from "../assets/PortfolioSnap1Buzz.PNG";
// import DndImage from "../assets/PortfolioSnap2Dnd.PNG";

// function Projects() {
//   return (
//     <div className="projects">
//       <h1>My Personal Projects</h1>
//       <div className="projectList">
//         {/* Project 1 */}
//         <div className="projectItem">
//           <a href={BuzzImage} target="_blank" rel="noopener noreferrer">
//             <img src="/assets/PortfolioSnap1Buzz" alt="BuzzFeed Clone Project" className="projectImage" />
//           </a>
//           <p className="projectDescription">
//             BuzzFeed Style Quiz App: Discover what kind of cheese you are with this fun, interactive quiz app.
//           </p>
//         </div>

//         {/* Project 2 */}
//         <div className="projectItem">
//           <a href={DndImage} target="_blank" rel="noopener noreferrer">
//             <img src="/assets/PortfolioSnap2Dnd" alt="DnD Language Learning App" className="projectImage" />
//           </a>
//           <p className="projectDescription">
//             DnD Language Learning App: Learn a new language with a twist! This app uses Dungeons & Dragons lore to make learning fun.
//           </p>
//         </div>
//       </div>
//     </div>
//   );
// }

// export default Projects;

