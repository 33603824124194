import React from "react";

function Experience() {
  return (
    <div className="experience">
      <h2>Professional Experience</h2>
      <div className="experience-item">
        <h3>Web Development Instructor</h3>
        <p><em>2021 - 2024 | Remote</em></p>
        <ul>
          <li>Resolved 200 code errors, computer issues, coding environment problems, and setup challenges, maintaining a 95% satisfaction rate among clients.</li>
          <li>Developed 20 assignments, 5 curriculum modules, and 10 instructional videos, enhancing learning engagement and student comprehension by 30%.</li>
          <li>Delivered guidance and instruction to 150 students on various coding languages and web development concepts, resulting in a 25% increase in student retention and completion rates.</li>
          <li>Provided technical support and troubleshooting for network systems supporting 1,300 users, optimizing efficiency and minimizing downtime through proactive maintenance and responsive problem-solving.</li>
          <li>Managed a ticketing system with a 95% completion rate, handling and successfully closing 10-15 tickets weekly to ensure prompt resolution of client issues and service requests, resulting in a 98% satisfaction rating.</li>
        </ul>
      </div>

      <div className="experience-item">
        <h3>Full Stack Teaching Assistant</h3>
        <p><em>2021 - 2022 | V School, Remote</em></p>
        <ul>
          <li>Supported 25 teachers in managing student-related issues and assessments, increasing classroom operations by 15% and student academic progress by 25%.</li>
          <li>Assisted 944 students in resolving development-related challenges, computer setup issues, and general learning obstacles, achieving a 90% issue resolution rate.</li>
          <li>Conducted training sessions for 300 students on software tools and programming concepts, resulting in a 15% improvement in student comprehension and practical skills development.</li>
        </ul>
      </div>

      <h2>Education & Certifications</h2>
      <div className="education-item">
        <h3>CompTIA A+ Certified</h3>
        <p><em>Expected 2024</em></p>
      </div>

      <div className="education-item">
        <h3>Google IT Support Professional Certification</h3>
        <p><em>Expected 2024</em></p>
      </div>

      <div className="education-item">
        <h3>IT Professional Certification</h3>
        <p><em>2024 | CourseCareers</em></p>
      </div>

      <div className="education-item">
        <h3>Full Stack Web Development Certification</h3>
        <p><em>2021 | V School</em></p>
      </div>

      <div className="education-item">
        <h3>Information Security Certification</h3>
        <p><em>2018</em></p>
      </div>
    </div>
  );
}

export default Experience;



// import React from "react";
// import {
//   VerticalTimeline,
//   VerticalTimelineElement,
// } from "react-vertical-timeline-component";
// import "react-vertical-timeline-component/style.min.css";
// // import SchoolIcon from "@material-ui/icons/School";
// // import WorkIcon from "@material-ui/icons/Work";

// function Experience() {
//   return (
//     <div className="experience">
//       <VerticalTimeline>
//         <VerticalTimelineElement
//           className="vertical-timeline-element--work"
//           contentStyle={{ background: "#f9f9f9", color: "#000" }}
//           contentArrowStyle={{ borderRight: "7px solid  #f9f9f9" }}
//           date="2021 - 2024"
//           iconStyle={{ background: "#3e497a", color: "#fff" }}
//           icon={<WorkIcon />}
//         >
//           <h3 className="vertical-timeline-element-title">Web Development Instructor</h3>
//           <h4 className="vertical-timeline-element-subtitle">Remote</h4>
//           <p>
//             • Resolved 200 code errors, computer issues, coding environment problems, and setup challenges, maintaining a 95% satisfaction rate among clients.
//             <br />
//             • Developed 20 assignments, 5 curriculum modules, and 10 instructional videos, enhancing learning engagement and student comprehension by 30%.
//             <br />
//             • Delivered guidance and instruction to 150 students on various coding languages and web development concepts, resulting in a 25% increase in student retention and completion rates.
//             <br />
//             • Provided technical support and troubleshooting for network systems supporting 1,300 users, optimizing efficiency, and minimizing downtime through proactive maintenance and responsive problem-solving.
//             <br />
//             • Managed a ticketing system with a 95% completion rate, handling and successfully closing 10-15 tickets weekly to ensure prompt resolution of client issues and service requests, resulting in a 98% satisfaction rating.
//           </p>
//         </VerticalTimelineElement>

//         <VerticalTimelineElement
//           className="vertical-timeline-element--work"
//           contentStyle={{ background: "#f9f9f9", color: "#000" }}
//           contentArrowStyle={{ borderRight: "7px solid  #f9f9f9" }}
//           date="2021 - 2022"
//           iconStyle={{ background: "#3e497a", color: "#fff" }}
//           icon={<WorkIcon />}
//         >
//           <h3 className="vertical-timeline-element-title">Full Stack Teaching Assistant</h3>
//           <h4 className="vertical-timeline-element-subtitle">V School | Remote</h4>
//           <p>
//             • Supported 25 teachers in managing student-related issues and assessments, increasing classroom operations by 15% and student academic progress by 25%.
//             <br />
//             • Assisted 944 students in resolving development-related challenges, computer setup issues, and general learning obstacles, fostering a conducive learning environment and achieving a 90% issue resolution rate.
//             <br />
//             • Conducted training sessions for 300 students on software tools and programming concepts, resulting in a 15% improvement in student comprehension and practical skills development.
//           </p>
//         </VerticalTimelineElement>

//         <VerticalTimelineElement
//           className="vertical-timeline-element--education"
//           contentStyle={{ background: "#f9f9f9", color: "#000" }}
//           contentArrowStyle={{ borderRight: "7px solid  #f9f9f9" }}
//           date="2024 (Expected)"
//           iconStyle={{ background: "#e17b77", color: "#fff" }}
//           // icon={<SchoolIcon />}
//         >
//           <h3 className="vertical-timeline-element-title">CompTIA A+ Certified</h3>
//         </VerticalTimelineElement>

//         <VerticalTimelineElement
//           className="vertical-timeline-element--education"
//           contentStyle={{ background: "#f9f9f9", color: "#000" }}
//           contentArrowStyle={{ borderRight: "7px solid  #f9f9f9" }}
//           date="2024 (Expected)"
//           iconStyle={{ background: "#e17b77", color: "#fff" }}
//           icon={<SchoolIcon />}
//         >
//           <h3 className="vertical-timeline-element-title">Google IT Support Professional Certification</h3>
//         </VerticalTimelineElement>

//         <VerticalTimelineElement
//           className="vertical-timeline-element--education"
//           contentStyle={{ background: "#f9f9f9", color: "#000" }}
//           contentArrowStyle={{ borderRight: "7px solid  #f9f9f9" }}
//           date="2024"
//           iconStyle={{ background: "#e17b77", color: "#fff" }}
//           icon={<SchoolIcon />}
//         >
//           <h3 className="vertical-timeline-element-title">IT Professional Certification</h3>
//           <h4 className="vertical-timeline-element-subtitle">CourseCareers</h4>
//         </VerticalTimelineElement>

//         <VerticalTimelineElement
//           className="vertical-timeline-element--education"
//           contentStyle={{ background: "#f9f9f9", color: "#000" }}
//           contentArrowStyle={{ borderRight: "7px solid  #f9f9f9" }}
//           date="2021"
//           iconStyle={{ background: "#e17b77", color: "#fff" }}
//           icon={<SchoolIcon />}
//         >
//           <h3 className="vertical-timeline-element-title">Full Stack Web Development Certification</h3>
//           <h4 className="vertical-timeline-element-subtitle">V School</h4>
//         </VerticalTimelineElement>

//         <VerticalTimelineElement
//           className="vertical-timeline-element--education"
//           contentStyle={{ background: "#f9f9f9", color: "#000" }}
//           contentArrowStyle={{ borderRight: "7px solid  #f9f9f9" }}
//           date="2018"
//           iconStyle={{ background: "#e17b77", color: "#fff" }}
//           icon={<SchoolIcon />}
//         >
//           <h3 className="vertical-timeline-element-title">Information Security Certification</h3>
//         </VerticalTimelineElement>
//       </VerticalTimeline>
//     </div>
//   );
// }

// export default Experience;


// import React from "react";


// function Experience() {
//   return (
//     <div className="experience">
      
//     </div>
//   );
// }

// export default Experience;